import clsx from "clsx";
import { Box } from "@chakra-ui/react";

const Spacer = ({
  size = "md",
  hideOnMobile = false,
  horizontalRule = false,
  borderColor = "#000000",
}) => {
  return (
    <div
      className={clsx("Spacer relative w-full", {
        block: !hideOnMobile,
        "hidden lg:block": hideOnMobile,
        "Spacer--size--none h-[1px]": size === "none",
        "Spacer--size--xs h-2.5 md:h-5 lg:h-5": size === "xs",
        "Spacer--size--sm h-5 md:h-7.5 lg:h-7.5": size === "sm",
        "Spacer--size--md h-7.5 sm:h-15 lg:h-15": size === "md",
        "Spacer--size--lg h-15 lg:h-30": size === "lg",
        "Spacer--size--xl h-30 lg:h-60": size === "xl",
      })}
    >
      {horizontalRule && (
        <Box
          className={clsx(
            "Spacer__horizontalRule h-[1px] w-full max-w-screen-xl m-auto",
            "absolute inset-0"
          )}
          background={borderColor ? borderColor : "#000000"}
        ></Box>
      )}
    </div>
  );
};

export default Spacer;
