import { useSetAtom } from "jotai";
import { useEffect } from "react";

import { headerThemeAtom } from "@atoms/siteHeader";

const useSetHeaderTheme = (headerTheme) => {
	const setHeaderTheme = useSetAtom(headerThemeAtom);

	return useEffect(() => {
		setHeaderTheme(headerTheme);
	}, [setHeaderTheme, headerTheme]);
};

export default useSetHeaderTheme;
