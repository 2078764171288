import clsx from "clsx";
import parse from "html-react-parser";
import Image from 'next/image'
import { useMeasure } from "react-use";

import getImageBlurOptions from "@lib/getImageBlurOptions";

const ContentImage = ({
	image,
	imageBlur,
	mobileImage,
	mobileImageBlur,
	caption,
	captionPosition = "center",
}) => {
	if (!image) return null;

	const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

	return (
		<div
			// className={clsx("ContentImage", {
			// 	"xl:-ml-15 xl:-mr-15": captionPosition === "center",
			// 	"xl:-ml-44 xl:-mr-44": captionPosition !== "center",
			// })}
		>
			<div
				className={clsx("ContentImage__content", "flex", {
					"flex-col": captionPosition === "center" || !captionPosition,
					"flex-col xl:flex-row": captionPosition !== "center",
					"flex-col xl:flex-row-reverse": captionPosition === "right",
				})}
			>
				<div
					className={clsx("ContentImage__image", {
						"xl:w-1/2": captionPosition !== "center" || !captionPosition,
					})}
					ref={ref}
				>
					<Image
						src={image.url}
						layout="responsive"
						width={image.width}
						height={image.height}
						sizes={width !== undefined ? `${Math.round(width)}px` : "100vw"}
						alt={image.title}
						{...getImageBlurOptions(image, imageBlur)}
					/>
        </div>
        {!!caption && (
          <div
            className={clsx("ContentImage__caption", "mt-7.5 xl:mt-4", {
              "xl:w-1/2 xl:px-15": captionPosition !== "center",
            })}
          >
            <div className={clsx("prose")}>{parse(caption)}</div>
          </div>
        )}
			</div>
		</div>
	);
};

export default ContentImage;
