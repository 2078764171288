import { GetStaticPaths, GetStaticProps } from "next";
import ErrorPage from "next/error";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect, useState } from "react";
import { Seomatic } from "react-seomatic";

import PageSections from "@components/PageSections";
import PasswordPageLayout from "@components/PasswordPageLayout";
import useSetHeaderTheme from "@hooks/useSetHeaderTheme";
import { PreviewContent } from "@interfaces/PreviewContent.interface";
import getAllReviews from "@lib/getAllReviews";
import getEmbeddedForms from "@lib/getEmbeddedForms";
import getGlobals from "@lib/getGlobals";
import getGridEntries from "@lib/getGridEntries";
import isPage from "@lib/isPage";
import { returnStaticProps } from "@lib/returnStaticProps";
import craft from "@pages/api/craft";
import QueryEntryType from "@queries/QueryEntryType.graphql";
import QueryPage from "@queries/QueryPage.graphql";
import QuerySlugs from "@queries/QuerySlugs.graphql";
import { PHASE_PRODUCTION_BUILD } from "next/constants";

import { PLASMIC } from "@abnormal/plasmic-init";
import {
  addPlasmicSeedsEntries,
  checkIsPlasmicSeed,
  removePlasmicSeed
} from "@lib/plasmicHelpers";
import {
  ComponentRenderData,
  PlasmicComponent,
  PlasmicRootProvider,
  extractPlasmicQueryData
} from "@plasmicapp/loader-nextjs";
import {
  getActiveVariation,
  rewriteWithoutTraits
} from "@plasmicapp/loader-nextjs/edge";
import * as React from "react";

import { PlasmicContext } from "../context/plasmic";

export const getStaticPaths: GetStaticPaths = async () => {
	if (process.env.NEXT_PHASE !== PHASE_PRODUCTION_BUILD) {
		await isPage.getRedirects();
		await isPage.fetchAndSetPages();
	}
	// Fetch every possible page path from craft, includes homepage
	const pages = await craft(QuerySlugs, { section: ["pages"], limit: null });
	const landingPages = await craft(QuerySlugs, {
		section: ["landingPages"],
		limit: 10,
	});

	const entries = pages.entries.concat(landingPages.entries);

	entries.map(async (page) => {
		console.log(`Generating /${page.uri}`);
	});

	// add extra paths for plasmic pages with A/B testing enabled
	const paths = addPlasmicSeedsEntries(entries);

	return {
		paths: paths,
		fallback: "blocking",
	};
};

export const getStaticProps: GetStaticProps = async ({
	params,
	previewData: nextPreviewData,
	preview,
}) => {
	// For Craft preview capability
	const previewData: PreviewContent = nextPreviewData as PreviewContent;
	const { slug } = params ?? {};

	let uri = "homepage";
	if (typeof slug !== "undefined") {
		uri = (slug as string[]).join("/");
	}
	const isPlasmicSeed = checkIsPlasmicSeed(uri);

	//check for plasmic seed and remove from uri if present
	if (isPlasmicSeed) {
		uri = removePlasmicSeed(uri);
	}

	console.log(`Preview mode enabled: ${!!preview}`);

	let devMode = false;

  if (
    process.env.NEXT_PUBLIC_LOG_LEVEL === "debug" ||
		process.env.VERCEL_ENV === "preview" ||
		process.env.NODE_ENV !== "production"
  ) {
    devMode = true;
    console.log(`Dev mode enabled: ${!!devMode}`);
  }

	if (!preview && !devMode) {
		console.log(`Getting page cache for: ${uri}`);
		const page = await isPage.cache.getPages(uri as string);

		if (!page) {
			const redirect = await isPage.cache.getRedirects(uri);
			//console.log(redirect)
			if (redirect) {
				return {
					redirect: {
						destination: redirect.redirectDestUrl,
						permanent: redirect.redirectHttpCode === 301,
					},
				};
			} else {
				return {
					notFound: true,
				};
			}
		}
	}

	const response = await craft(
		QueryPage,
		{
			uri,
			drafts:
				!preview &&
        (process.env.NEXT_PUBLIC_LOG_LEVEL === "debug" ||
          process.env.VERCEL_ENV === "preview" ||
					process.env.NODE_ENV !== "production") &&
				null,
      status:
        process.env.NEXT_PUBLIC_LOG_LEVEL === "debug" ||
				process.env.VERCEL_ENV === "preview" ||
				process.env.NODE_ENV !== "production"
					? null
					: "enabled",
		},
		preview ? previewData.token : null
  );

	const pageTypeResponse = await craft(QueryEntryType, {
		uri,
	});
	const { pageType } = pageTypeResponse;

	const {
		pageEntry,
		landingPageEntry,
		primaryNavigation,
		primaryCallToActions,
		persistentNav,
		globalSets,
	} = response;

	//always show persistentNav on homepage
  if (persistentNav && uri === "homepage") {
    persistentNav.showOnAllPages = true;
  }

	let returnResponse = null;

	let plasmicData;
	let pageMeta;
	let queryCache;
	let variation;
	let externalIds;
  const isPlasmicABTest = pageEntry?.plasmicABTesting || landingPageEntry?.plasmicABTesting || null;

	if (pageType?.typeHandle === "plasmic") {
		let rawPlasmicPath = "/";
		if (typeof slug !== "undefined") {
			rawPlasmicPath = `/${(slug as string[]).join("/")}`;
		}
		// Parse the path, and extract the traits.
		const { path: plasmicPath, traits } = rewriteWithoutTraits(rawPlasmicPath);
    plasmicData = await PLASMIC.maybeFetchComponentData([plasmicPath, "Footer"], {
      // deferChunks: true,
    });
		// Pick the variation to use based on the traits
		if (isPlasmicABTest) {
			variation = getActiveVariation({
				splits: PLASMIC.getActiveSplits(),
				traits,
				path: plasmicPath,
			});
      externalIds = PLASMIC.getExternalVariation(variation, {
        // Filter the external IDs to only include the ones for the projects used in this page
        projectIds: plasmicData.entryCompMetas.map((m) => m.projectId),
      });
		} else variation = { variation: null };
	}

	// This is a path that Plasmic knows about.
	if (plasmicData) {
		pageMeta = plasmicData.entryCompMetas[0];

		// Cache the necessary data fetched for the page.
		queryCache = await extractPlasmicQueryData(
			<PlasmicRootProvider
				loader={PLASMIC}
				prefetchedData={plasmicData}
				pageParams={pageMeta.params}
				variation={variation}
			>
				<PlasmicComponent component={pageMeta.displayName} />
			</PlasmicRootProvider>
		);
	}

	let notFound = false;

	if (!landingPageEntry && !pageEntry) {
		notFound = true;
	} else if (landingPageEntry?.typeHandle === "plasmic" && !plasmicData) {
		notFound = true;
	} else if (pageEntry?.typeHandle === "plasmic" && !plasmicData) {
		notFound = true;
	}

	if (response.landingPageEntry != null) {
		const gridPosts = await getGridEntries(
			response.landingPageEntry?.allPageSections
		);
		const forms = await getEmbeddedForms(
			response.landingPageEntry?.allPageSections
		);

		const allReviews = await getAllReviews(response.pageEntry?.allPageSections);

		const globals = await getGlobals(globalSets);

		// Return props if entry exists, otherwise check Retour for redirects or 404
		returnResponse = await returnStaticProps(
			{
				pageEntry,
				landingPageEntry,
				gridPosts,
				forms,
				persistentNav,
				globals,
				allReviews,
				plasmicData: plasmicData || null,
				queryCache: queryCache || null,
				variation: variation || { variation: null },
				externalIds: externalIds || null,
			},
			notFound,
			uri
		);
	} else {
		// Return props if entry exists, otherwise check Retour for redirects or 404
		const gridPosts = await getGridEntries(
			response.allPageSections?.allPageSections
		);
		const forms = await getEmbeddedForms(
			response.allPageSections?.allPageSections
		);

		const allReviews = await getAllReviews(
			response.allPageSections?.allPageSections
		);

		const globals = await getGlobals(globalSets);

		returnResponse = await returnStaticProps(
			{
				navigation: {
					primaryNavigation,
					primaryCallToActions,
				},
				pageEntry,
				landingPageEntry,
				gridPosts,
				forms,
				persistentNav,
				globals,
				allReviews,
				plasmicData: plasmicData || null,
				queryCache: queryCache || null,
				variation: variation || { variation: null },
				externalIds: externalIds || null,
			},
			notFound,
			uri
		);
	}

	return returnResponse;
};

interface IProps {
	plasmicData?: ComponentRenderData;
	queryCache?: Record<string, any>;
	pageEntry: any;
	landingPageEntry: any;
	gridPosts: any;
	forms: any;
	globals: any;
	allReviews: any;
	variation: any;
	externalIds: any;
}

export default function Page({
	plasmicData,
	queryCache,
	pageEntry,
	landingPageEntry,
	gridPosts,
	forms,
	globals,
	allReviews,
	variation,
	externalIds,
}: IProps): JSX.Element {
	const router = useRouter();

	const noPlasmicData =
		((pageEntry && pageEntry.typeHandle !== "plasmic") ||
			(landingPageEntry && landingPageEntry.typeHandle !== "plasmic")) |
			!plasmicData || plasmicData.entryCompMetas.length === 0;

	let entry = null;

	if (pageEntry == null && landingPageEntry != null) {
		entry = landingPageEntry;
	} else {
		entry = pageEntry;
	}

	useEffect(() => {
		if (pageEntry == null && landingPageEntry != null) {
			window.dataLayer?.push({
				event: "event",
				eventProps: {
					category: "Landing Page View",
					action: landingPageEntry.title,
					label: landingPageEntry.uri,
				},
			});
		}
	});

	useSetHeaderTheme(entry?.headerTheme);

	const [accessGranted, setAccessGranted] = useState(false);

	if (!router.isFallback && !entry) {
		return <ErrorPage statusCode={404} />;
	}

	// Check if password protected
	const isPasswordProtected = !!entry?.encryptedPassword;

	let faqs = [];
	let faqSchema = {};

	if (entry?.faqSchema?.length) {
		entry?.faqSchema.map((item) => {
			faqs.push({
				"@type": "Question",
				name: item.question,
				acceptedAnswer: {
					"@type": "Answer",
					text: item.answer,
				},
			});
		});

		faqSchema = {
			"@context": "https://schema.org",
			"@type": "FAQPage",
			mainEntity: faqs,
		};
	}

	let pageMeta;
	if (!noPlasmicData) {
		pageMeta = plasmicData.entryCompMetas[0];
	}
	return (
		<>
			<Seomatic Head={Head} {...entry?.seomatic} />
			{entry?.faqSchema?.length > 0 && (
				<Script
					type="application/ld+json"
					id="faqSchema"
					dangerouslySetInnerHTML={{ __html: JSON.stringify(faqSchema) }}
				/>
			)}
			{!noPlasmicData && (
				<PlasmicContext
					pageProps={{ pageEntry: pageEntry || landingPageEntry }}
				>
					<PlasmicRootProvider
						loader={PLASMIC}
						prefetchedData={plasmicData}
						prefetchedQueryData={queryCache}
						pageParams={pageMeta.params}
						pageQuery={router.query}
						variation={variation}
					>
						{
							// pageMeta.displayName contains the name of the component you fetched.
						}
						<PlasmicComponent component={pageMeta.displayName} />
					</PlasmicRootProvider>
				</PlasmicContext>
			)}

			{noPlasmicData &&
				!!entry?.pageSections &&
				isPasswordProtected &&
				!accessGranted && (
					<PageSections
						sections={[entry?.pageSections[0]]}
						gridPosts={gridPosts}
						forms={forms}
						globals={globals}
						allReviews={allReviews}
						sectionNav={!!entry.sectionNavigation}
					/>
				)}
			{noPlasmicData &&
				!!entry?.pageSections &&
				((isPasswordProtected && accessGranted) || !isPasswordProtected) && (
					<PageSections
						sections={entry?.pageSections}
						gridPosts={gridPosts}
						forms={forms}
						globals={globals}
						allReviews={allReviews}
						sectionNav={!!entry.sectionNavigation}
					/>
				)}
			{noPlasmicData && isPasswordProtected && !accessGranted && (
				<PasswordPageLayout
					contentSections={entry?.contentSections}
					encryptedPassword={entry?.encryptedPassword}
					onFormSuccess={() => setAccessGranted(true)}
					sectionNav={!!entry.sectionNavigation}
				/>
			)}
		</>
	);
}
