import SecondaryButton from "@components/SecondaryButton";
import clsx from "clsx";

const FileDownloadButtons = ({
  files,
  alignment = "left",
  legacyBackgroundColor = "#6863F2",
  textColor="#fff"
}) => {
	return (
		<>
			{files.map((file) => {
				return (
					<div
						key={file.id}
						className={clsx("DownloadButtons__button flex flex-row", {
							"justify-center": alignment === "center",
							"justify-end": alignment === "right",
						})}
          >
            <SecondaryButton 
              target={{
                type: "asset",
                url: file.url,
                text: `Download ${file.kind.toUpperCase()}`,
              }}
              legacyBackgroundColor={legacyBackgroundColor}
              textColor={textColor}
            />
					</div>
				);
			})}
		</>
	);
};

export default FileDownloadButtons;
